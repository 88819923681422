import React from "react";

export const E2Glyph = ({
    name,
    type,
    color,
    className,
    ...rest
}) => {

    const props = {
        name,
        type,
        color,
        class: className,
        ...rest
    }

    return <e2-glyph {...props} />;
}