import React, { useState } from "react";
import * as LZString from 'lz-string';

import css from "./SearchPanel.module.scss";
import { APP_ROOT } from "../../constants";
import { PanelButton } from "../PanelButton";

export const SearchPanel = () => {

	const [text, setText] = useState(``);

	const openSearch = (stringParams) => {
		const params = stringParams ? `?${stringParams}` : ``;
		window.location.assign(`${APP_ROOT}${params}`);
	}

	const openFavorites = () => {
		window.location.assign(`${APP_ROOT}/favorites`);
	}

    const runSearch = () => {
        let paramsBySections = {};

        if (text) {
            paramsBySections = {
                isearch: {
                    text
                }
            };
        } else {
            openSearch();
			return;
        }

        const searchData = LZString.compressToEncodedURIComponent(JSON.stringify(paramsBySections));

		const stringParams = new URLSearchParams({ searchData }).toString();

		openSearch(stringParams);
    }

	const onChange = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const value = event.target.value;

		setText(value);
	};

	const onKeyDown = (event) => {
		const { key } = event;
		if (key === `Enter`) {
			runSearch();
		}
	};

	const textInputProps = {
		value: text === null ? `` : text,
		onChange,
		onKeyDown,
		className: css.inputControll,
		placeholder: `Введите полностью или часть наименования, номера, ИКЗ, используя ключевое слово`,
	}

	return (
		<div className={css.component}>
			<input {...textInputProps} />
			<div className={css.searchOptionsPanel}>
				<PanelButton
					icon={`settings`}
					onClick={() => openSearch()}
					title={`Параметры`}
				/>
				<PanelButton
					icon={`favorites`}
					onClick={openFavorites}
					title={`Избранные`}
				/>
				<PanelButton
					icon={`search`}
					color={`var(--isearch-fields-border, #4db2ff)` }
					onClick={runSearch}
					title={`Интеллектуальный поиск`}
				/>
			</div>
		</div>
	);
};
