import React from "react";
import { E2Glyph } from "../../e2-controls/E2Glyph";
import cn from "classnames";

import css from "./PanelButton.module.scss";

export const PanelButton = ({
    icon,
    color = `var(--isearch-links, #4db2ff)`,
    colorDisable = `var(--isearch-disabled, #e0e0e0)`,
    title,
    count,
    disabled = false,
    onClick,
}) => {

    const props = {
        className: cn(css.component, { [css.disabled]: disabled }),
        onClick: disabled ? undefined : onClick,
        title,
    }

    return (
        <div {...props}>
            <E2Glyph
                name={icon}
                className={css.icon}
                color={disabled ? colorDisable : color}
            />
            {typeof count === `number` && count > 0 && <div className={css.count}>{count}</div>}
        </div>
    );
}