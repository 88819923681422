import React from "react";
import { ReactDOM, createRoot } from "react-dom/client";

import { SearchPanel } from "./components/SearchPanel";

const WIDGET_WEBCOMPONENT_NAME = `isearch-widget`;

class ISearchWidget extends HTMLElement {

    connectedCallback() {
        this.mountReactApp();
    }

    disconnectedCallback() {
        ReactDOM.unmountComponentAtNode(this.mountPoint);
    }

    mountReactApp() {
        if (!this.mountPoint) {
            this.mountPoint = document.createElement(`div`);
            this.mountPoint.style = `height: 100%`;
            this.attachShadow({ mode: `open` }).appendChild(this.mountPoint);
        }

        // переносим стили из контейнера веб-компонента в shadowRoot
        Array.from(this.querySelectorAll(`style`)).forEach(style => {
            this.shadowRoot?.prepend(style);
        });

        const root = createRoot(this.mountPoint);
        root.render(<SearchPanel />);
    }
}


Promise.all([
    import(/* webpackIgnore: true */ `/components/glyph.js`),
]).finally(() => {
    window.customElements.define(WIDGET_WEBCOMPONENT_NAME, ISearchWidget);
});

