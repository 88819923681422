// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PanelButton_component__3kzeC{position:relative;cursor:pointer}.PanelButton_disabled__3mQCT{cursor:not-allowed}.PanelButton_icon__4NXN8:hover{filter:brightness(79.5%)}", "",{"version":3,"sources":["webpack://src/components/PanelButton/PanelButton.module.scss"],"names":[],"mappings":"AAAA,8BACI,iBAAA,CACA,cAAA,CAGJ,6BACI,kBAAA,CAIA,+BACI,wBAAA","sourcesContent":[".component {\n    position: relative;\n    cursor: pointer;\n}\n\n.disabled {\n    cursor: not-allowed;\n}\n\n.icon {\n    &:hover {\n        filter: brightness(79.5%);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "PanelButton_component__3kzeC",
	"disabled": "PanelButton_disabled__3mQCT",
	"icon": "PanelButton_icon__4NXN8"
};
export default ___CSS_LOADER_EXPORT___;
